import {zodResolver} from '@hookform/resolvers/zod';
import type {SxProps} from '@mui/material';
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import useResendMemberEmail from '@/hooks/useResendMemberEmail';
import type {SignInResponse} from '@/types/SignInResponse';
import {errorMap} from '@/utils/zod';

const checkEmailInput = z.string().email();

const resendMemberIdFormSchema = z.object({
    email: z.string(),
});

export type ResendMemberIdFormSchemaType = z.infer<typeof resendMemberIdFormSchema>;

type Props = {
    initialEmailValue : string;
    linkText ?: string;
    useModal ?: boolean;
    linkTextSx ?: SxProps;
    sx ?: SxProps;
};

const SendMemberIdEmailRequest : React.FC<Props> = (
    {
        initialEmailValue,
        linkText = 'Resend ID',
        useModal = false,
        linkTextSx = {},
        sx = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    } : Props
) => {
    const [open, setOpen] = React.useState(false);
    const [emailInvalid, setEmailInvalid] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');
    const resendMemberEmail = useResendMemberEmail();

    const processRequest = async (email : string) => {
        setErrorMessage('');
        setSuccessMessage('');

        const response = await resendMemberEmail(email);

        if (response.ok) {
            const signInResponseJson = await response.json() as SignInResponse;

            if (signInResponseJson.errorCode !== 0) {
                setErrorMessage(signInResponseJson.message);
                return;
            }

            if (signInResponseJson.message !== '') {
                setSuccessMessage(signInResponseJson.message);
                setOpen(false);
                return;
            }
        }

        setErrorMessage('Error: Login Unsuccessful');
    };

    const handleClickLink = async () => {
        if (useModal) {
            setOpen(true);
            return;
        }

        await processRequest(initialEmailValue);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorMessage('');
    };

    const resendMemberIdForm = useForm<ResendMemberIdFormSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            email: initialEmailValue,
        },
        resolver: zodResolver(resendMemberIdFormSchema, {errorMap}),
    });

    const handleResendId = async () => {
        setEmailInvalid(false);

        const email = resendMemberIdForm.watch('email');

        try {
            const {success} = checkEmailInput.safeParse(email);

            if (success) {
                await processRequest(email);
            } else {
                setEmailInvalid(true);
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    useEffect(() => {
        resendMemberIdForm.setValue('email', initialEmailValue);
    }, [initialEmailValue]);

    useEffect(() => {
        setTimeout(() => {
            if (successMessage !== '') {
                setSuccessMessage('');
            }
        }, 4000);
    }, [successMessage]);

    return <>
        <Stack sx={{flexDirection: 'row', ...sx}}>
            <Link onClick={handleClickLink} sx={{...linkTextSx}}>{linkText}</Link>
            {successMessage && <Alert severity='success'>{successMessage}</Alert>}
            {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
        </Stack>
        {useModal && <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogTitle>Resend membership ID</DialogTitle>
            <DialogContent>
                <form noValidate>
                    <Stack>
                        <RhfTextField
                            control={resendMemberIdForm.control}
                            name="email"
                            label="Email Address"
                            placeholder="Email Address"
                            sx={{my: 1}}
                            onChangeCapture={() => {
                                resendMemberIdForm.clearErrors();
                            }}
                        />
                        {emailInvalid && <Alert severity='error'>Invalid Email</Alert>}
                        {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
                    </Stack>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleResendId}>Resend</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>}
    </>;
};

export default SendMemberIdEmailRequest;
