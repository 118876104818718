import {zodResolver} from '@hookform/resolvers/zod';
import {Alert, Box, Button, Container, Grid, Stack} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';
import PaperWithLabel from '@/components/PaperWithLabel';
import useRegisterMember from '@/hooks/useRegisterMember';
import {useUserContext} from '@/providers/UserContext';
import {errorMap} from '@/utils/zod';

const studentLoginFormSchema = z.object({
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string().optional(),
    address: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zip: z.string().optional(),
});

export type StudentLoginFormSchemaType = z.infer<typeof studentLoginFormSchema>;

export type HandleSubmitResponse = {
    'errorCode' : number;
    'message' : string;
    'data' :
    {
        'fullName' : string;
        'memberId' : string;
    };
};

const RegisterForm = () : React.ReactElement => {
    const {setUser} = useUserContext();
    const navigate = useNavigate();
    const registerMember = useRegisterMember();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const form = useForm<StudentLoginFormSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {},
        resolver: zodResolver(studentLoginFormSchema, {errorMap}),
    });

    const handleSubmit = async () => {
        const formValues = form.getValues();

        setErrorMessage('');
        setSuccessMessage('');

        const response = await registerMember(
            formValues.email,
            formValues.firstName,
            formValues.lastName,
            (formValues.phone ? formValues.phone : ''),
            (formValues.address ? formValues.address : ''),
            (formValues.city ? formValues.city : ''),
            (formValues.state ? formValues.state : ''),
            (formValues.zip ? formValues.zip : ''),
        );

        if (response.ok) {
            const json = await response.json() as HandleSubmitResponse;

            if (json.errorCode !== 0) {
                setErrorMessage(json.message);
                return;
            }

            setSuccessMessage(json.message + ' You are being redirected.');

            // set local storage and redirect to home page
            const user = {
                email: formValues.email,
                memberId: json.data.memberId,
                fullName: json.data.fullName,
            };

            setUser(user);
            setTimeout(() => {
                navigate('/');
            }, 1500);
            return;
        } else {
            setErrorMessage('An unknown error has occurred.');
        }
    };

    return <Container maxWidth='sm' sx={{mt: 3}}>
        <PaperWithLabel label='Membership Information'>
            <Box>
                {errorMessage !== '' && <Box>
                    <Alert severity="error" sx={{mb: 2}}>{errorMessage}</Alert>
                    <Stack direction="row" justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            type="button"
                            color="primary"
                            sx={{mr: 2}}
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            Go To Sign In Page
                        </Button>
                        <Button
                            variant="outlined"
                            type="button"
                            color="secondary"
                            onClick={() => {
                                setErrorMessage('');
                            }}
                        >
                            Start Over
                        </Button>
                    </Stack>
                </Box>}
                {successMessage !== '' && <Alert severity="success">{successMessage}</Alert>}
                {errorMessage === '' && successMessage === ''
                    && <form onSubmit={form.handleSubmit(handleSubmit)} noValidate style={{marginTop: '10px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <RhfTextField
                                    control={form.control}
                                    name="firstName"
                                    label="First Name"
                                    placeholder="First Name"
                                    sx={{width: '100%'}}
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RhfTextField
                                    control={form.control}
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    sx={{width: '100%'}}
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RhfTextField
                                    control={form.control}
                                    name="phone"
                                    label="Phone"
                                    placeholder="Phone"
                                    sx={{width: '100%'}}
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RhfTextField
                                    control={form.control}
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    sx={{width: '100%'}}
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <RhfTextField
                                    control={form.control}
                                    name="address"
                                    label="Address"
                                    placeholder="Address"
                                    sx={{width: '100%'}}
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <RhfTextField
                                    control={form.control}
                                    name="city"
                                    label="City"
                                    placeholder="City"
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RhfTextField
                                    control={form.control}
                                    name="state"
                                    label="State"
                                    placeholder="State"
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <RhfTextField
                                    control={form.control}
                                    name="zip"
                                    label="Zip"
                                    placeholder="Zip"
                                    onChangeCapture={() => {
                                        form.clearErrors();
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Button
                                        variant="outlined"
                                        type="button"
                                        color="secondary"
                                        onClick={() => {
                                            navigate('/');
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </form>}
            </Box>
        </PaperWithLabel>
    </Container>;
};

export default RegisterForm;
