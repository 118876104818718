import {zodResolver} from '@hookform/resolvers/zod';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Stack,
    useTheme,
} from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {RhfTextField} from 'mui-rhf-integration';
import type * as React from 'react';
import {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import PaymentModal from '@/components/PaymentModal';
import useFetchAvailableMemberships from '@/hooks/useFetchAvailableMemberships';
import useStripeCreateSetupIntent from '@/hooks/useStripeCreateSetupIntent';
import {useUserContext} from '@/providers/UserContext';
import type {ActiveMembership} from '@/types/ActiveMembership';
import type {AvailableMembershipsResponse} from '@/types/AvailableMembershipResponse';
import type {CreateSetupIntentResponse} from '@/types/CreateSetupIntentResponse';
import type {Membership} from '@/types/Membership';
import type {SetupIntent} from '@/types/SetupIntent';
import {errorMap} from '@/utils/zod';

const stripePromise = await loadStripe(import.meta.env.VITE_APP_STRIPE_KEY as string);

const newMembershipFormSchema = z.object({
    school: z.string(),
    type: z.string(),
});

export type NewMembershipFormSchemaType = z.infer<typeof newMembershipFormSchema>;

type Props = {
    activeMemberships : ActiveMembership[];
};

const NewMembershipForm = ({activeMemberships} : Props) : React.ReactElement => {
    const theme = useTheme();
    const {user} = useUserContext();
    const createSetupIntent = useStripeCreateSetupIntent();
    const fetchAvailableMemberships = useFetchAvailableMemberships();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [availableMemberships, setAvailableMemberships] = useState<Membership[]>([]);
    const [selectedMembership, setSelectedMembership] = useState<Membership | null>(null);

    const [open, setOpen] = useState(false);
    const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);

    const filteredMemberships = availableMemberships.filter(membership => {
        return !activeMemberships.some(activeMembership => {
            return activeMembership.membershipTypeID === membership.membershipTypeID;
        });
    });

    const form = useForm<NewMembershipFormSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {},
        resolver: zodResolver(newMembershipFormSchema, {errorMap}),
    });

    const schoolValue = form.watch('school');
    const typeValue = form.watch('type');
    const isFormValid = Object.keys(form.formState.errors).length === 0;

    const handleClosePaymentModal = () => {
        setOpen(false);
        setSelectedMembership(null);
    };

    const handleSubmit = async () => {
        if (user && selectedMembership) {
            setErrorMessage('');
            setSuccessMessage('');

            const response = await createSetupIntent(
                user,
                selectedMembership.membershipTypeID,
            );

            if (response.ok) {
                const json = await response.json() as CreateSetupIntentResponse;

                if (json.errorCode !== 0) {
                    setErrorMessage(json.message);
                    return;
                }

                setSetupIntent(json.data as SetupIntent);
                setOpen(true);

                return;
            } else {
                setErrorMessage('An unknown error has occurred.');
            }
        }
    };

    const handleSelectMembership = async (membership : Membership) => {
        setSelectedMembership(membership);
    };

    useEffect(() => {
        const doFetchAvailableMemberships = async (memberId : string) => {
            setErrorMessage('');

            const response = await fetchAvailableMemberships(memberId);

            if (response.ok) {
                const json = await response.json() as AvailableMembershipsResponse;

                if (json.errorCode !== 0) {
                    setErrorMessage(json.message);
                    return;
                }

                setAvailableMemberships(json.data);
                return;
            }

            setErrorMessage('Error: Unable to fetch Active Memberships');
        };

        if (user) {
            void doFetchAvailableMemberships(user.memberId);
        }
    }, []);

    useEffect(() => {
        form.setValue('type', '');
        setSelectedMembership(null);
    }, [schoolValue]);

    useEffect(() => {
        setSelectedMembership(null);
    }, [typeValue]);

    useEffect(() => {
        const handleFormSubmit = async () => {
            await handleSubmit();
        };

        if (selectedMembership) {
            void handleFormSubmit();
        }
    }, [selectedMembership]);

    return <Box>
        {successMessage !== '' && <Stack direction='column'>
            <Alert severity="success">{successMessage}</Alert>
            <Stack direction='row' justifyContent='flex-end' sx={{mt: 2}}>
                <Button
                    variant="outlined"
                    type="submit"
                    color="primary"
                    onClick={() => {
                        setErrorMessage('');
                        setSuccessMessage('');
                    }}
                >
                    Complete
                </Button>
            </Stack>
        </Stack>}
        {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
        {filteredMemberships.length === 0 && <Alert severity='error'>No available memeberships found.</Alert>}
        {errorMessage === '' && successMessage === '' && filteredMemberships.length > 0
            && <form onSubmit={form.handleSubmit(handleSubmit)} noValidate style={{marginTop: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RhfTextField
                            select
                            control={form.control}
                            name="school"
                            label="Select School"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                '& .MuiSelect-outlined': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiSelect-iconOutlined': {mr: 4},
                            }}
                            InputProps={{
                                sx: {
                                    pr: '2px',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {schoolValue && <IconButton
                                            disableRipple
                                            onClick={() => {
                                                form.setValue('school', '');
                                            }}
                                        >
                                            <ClearIcon/>
                                        </IconButton>}
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {[...new Set(filteredMemberships.map(activeMembership => activeMembership.school))]
                                .map(school => {
                                    return <MenuItem key={`school-${school}`} value={school}>{school}</MenuItem>;
                                })}
                        </RhfTextField>
                    </Grid>
                    <Grid item xs={12}>
                        {schoolValue && <RhfTextField
                            select
                            control={form.control}
                            name="type"
                            label="Select Membership Type"
                            // helperText="School"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                '& .MuiSelect-outlined': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiSelect-iconOutlined': {mr: 4},
                            }}
                            InputProps={{
                                sx: {
                                    pr: '2px',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {typeValue && <IconButton
                                            disableRipple
                                            onClick={() => {
                                                form.setValue('type', '');
                                            }}
                                        >
                                            <ClearIcon/>
                                        </IconButton>}
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {[...new Set(filteredMemberships
                                .filter(activeMembership => activeMembership.school === schoolValue)
                                .map(activeMembership => activeMembership.type))].map(type => {
                                return <MenuItem key={`type-${type}`} value={type}>{type}</MenuItem>;
                            })}
                        </RhfTextField>}
                    </Grid>
                    <Grid item xs={12}>
                        {schoolValue && typeValue && <Paper sx={{fontSize: '14px'}}>
                            <Grid container item spacing={2} xs={12}>
                                <Grid item xs={12} md={1} sx={{fontWeight: 800}}>
                                    <Box sx={{ml: 2}}>Tier</Box>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{fontWeight: 800}}>
                                    <Box sx={{ml: 2}}>Description</Box>
                                </Grid>
                                <Grid item xs={12} md={1} sx={{fontWeight: 800}}>
                                    <Box sx={{ml: 2}}>Cost</Box>
                                </Grid>
                                <Grid item xs={12} md={2} sx={{fontWeight: 800}}>

                                </Grid>
                                {[...new Set(filteredMemberships
                                    .filter(activeMembership => activeMembership.school === schoolValue)
                                    .filter(activeMembership => activeMembership.type === typeValue))]
                                    .map(membershipTier => {
                                        return <Fragment key={`membership-${membershipTier.membershipTypeID}`}>
                                            <Grid item xs={12} md={12}>
                                                <Divider sx={{bgcolor: '#333', height: '1px'}}/>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Box sx={{ml: 2, mb: 2}}>{membershipTier.tier}</Box>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Box sx={{ml: 2, mb: 2, whiteSpace: 'pre-wrap'}}>
                                                    {membershipTier.description.replaceAll('\r', '\n')}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Box sx={{ml: 2, mb: 2}}>${membershipTier.cost}</Box>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Box sx={{ml: 2, mb: 2}}>
                                                    <Button
                                                        variant="outlined"
                                                        type="button"
                                                        disabled={Boolean(!isFormValid)}
                                                        color="primary"
                                                        onClick={async () => {
                                                            await handleSelectMembership(membershipTier);
                                                        }}
                                                    >
                                                        Purchase
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Fragment>;
                                    })}
                            </Grid>
                        </Paper>}
                    </Grid>
                </Grid>
            </form>}
        {user && setupIntent && selectedMembership && <Elements stripe={stripePromise} options={{
            clientSecret: setupIntent.clientSecret,
        }}>
            <PaymentModal
                open={open}
                handleClosePaymentModal={handleClosePaymentModal}
                description={selectedMembership.description}
                amount={selectedMembership.cost}
                membershipTypeId={selectedMembership.membershipTypeID}
                boosterMembershipTypeId={setupIntent.boosterMembershipTypeId}
            />
        </Elements>}
    </Box>;
};

export default NewMembershipForm;
