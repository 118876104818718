import {useCallback} from 'react';

const useFetchAvailableMemberships = () => {
    return useCallback(async (
        memberId : string,
    ) : Promise<Response> => {
        const url = new URL('/membership/available-memberships', import.meta.env.VITE_APP_API_ENDPOINT);

        return await fetch(url.toString(), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                memberId: memberId,
            }),
        });
    }, []);
};

export default useFetchAvailableMemberships;
