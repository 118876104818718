import {Container} from '@mui/material';
import type * as React from 'react';
import {useSearchParams} from 'react-router-dom';
import Barcode from '@/components/Barcode';

const MemberBarcode = () : React.ReactElement => {
    const [searchParameters] = useSearchParams();
    const memberId = searchParameters.get('memberId');

    return (
        <Container maxWidth="sm" sx={{mt: 3, textAlign: 'center'}}>
            <Barcode memberId={memberId}/>
        </Container>
    );
};

export default MemberBarcode;
