import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './main.scss';
import {createTheme, ThemeProvider} from "@mui/material";

const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#000',
                    borderColor: '#000',
                    '&:hover': {
                        color: '#fff !important',
                        backgroundColor: '#000',
                        borderColor: '#fff',
                    },
                },
            },
        },
    },
});

const root = createRoot(container);
root.render((
    <StrictMode>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </StrictMode>
));
