import type {SxProps} from '@mui/material';
import {Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import type {ReactElement} from 'react';

type Props = {
    label : string;
    sx ?: SxProps;
    children : ReactElement | ReactElement[];
};

const PaperWithLabel : React.FC<Props> = ({label, children, sx} : Props) => {
    return <Paper variant="outlined" sx={{mt: 2, position: 'relative', p: 3, ...sx}}>
        <Typography
            variant="body1"
            sx={{
                px: 1,
                position: 'absolute',
                top: -11,
                backgroundColor: '#ffffff',
                borderRadius: '4px',
            }}
        >
            {label}
        </Typography>
        {children}
    </Paper>;
};

export default PaperWithLabel;
