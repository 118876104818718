import {Alert, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import type * as React from 'react';
import type {ActiveMembership} from '@/types/ActiveMembership';

type Props = {
    activeMemberships : ActiveMembership[];
    errorMessage : string;
    infoMessage : string;
};

const ActiveMemberships = ({activeMemberships, errorMessage, infoMessage} : Props) : React.ReactElement => {
    return (
        <Box>
            {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
            {infoMessage !== '' && <Alert severity='info'>{infoMessage}</Alert>}
            {errorMessage === '' && infoMessage === '' && <TableContainer component={Paper}>
                <Table aria-label="Active Memberships">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 800}}>School</TableCell>
                            <TableCell sx={{fontWeight: 800}}>Type</TableCell>
                            <TableCell sx={{fontWeight: 800}}>Tier</TableCell>
                            <TableCell sx={{fontWeight: 800}}>Expires</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activeMemberships.map(row => (
                            <TableRow
                                key={row.membershipTypeID}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">{row.school}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.tier}</TableCell>
                                <TableCell>{row.endDate}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </Box>
    );
};

export default ActiveMemberships;
