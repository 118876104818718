import {Button, CircularProgress, FormGroup, FormLabel, Stack} from '@mui/material';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import './StripeForm.css';
import type {ReactElement} from 'react';
import {useState} from 'react';

type Props = {
    membershipTypeId : string;
    boosterMembershipTypeId : string;
    handleClose : () => void;
};

const StripeForm = ({membershipTypeId, boosterMembershipTypeId, handleClose} : Props) : ReactElement => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmitStripeSetupIntent = async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        const baseUrl = import.meta.env.VITE_APP_BASE_URL as string;

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${baseUrl}/payment-complete?`
                    + `membershipTypeId=${membershipTypeId}`
                    + `&boosterMembershipTypeId=${boosterMembershipTypeId}`,
            },
        });

        setIsProcessing(false);

        if (result.error.message !== undefined) {
            // setErrorMessage(result.error.message);
        }
    };

    return (
        <div>
            <form
                onSubmit={e => {
                    e.preventDefault();
                }}
                style={{marginTop: '10px'}}
            >
                <FormGroup>
                    <FormLabel>Payment Card</FormLabel>
                    <PaymentElement className='stripe-card-element'/>
                </FormGroup>
                {isProcessing && <CircularProgress/>}
                <Stack direction='row' justifyContent='flex-end'>
                    {!isProcessing && <Button
                        onClick={handleClose}
                        variant="outlined"
                        type="button"
                        color="primary"
                        sx={{mr: 3}}
                    >
                        Cancel
                    </Button>}
                    {!isProcessing && <Button
                        onClick={handleSubmitStripeSetupIntent}
                        variant="outlined"
                        type="button"
                        color="primary"
                    >
                        Submit
                    </Button>}
                </Stack>
            </form>
        </div>
    );
};

export default StripeForm;
