import {useCallback} from 'react';

const useResendMemberEmail = () : (
email : string,
) => Promise<Response> => {
    return useCallback(async (
        email : string,
    ) : Promise<Response> => {
        const init : RequestInit = {
            headers: new Headers({'Accept': 'application/json', 'Content-Type': 'application/json'}),
            method: 'POST',
        };

        init.body = JSON.stringify({
            email: email,
        });

        const url = new URL('/booster-member/resend-member-email', import.meta.env.VITE_APP_API_ENDPOINT);
        return await fetch(url.toString(), init);
    }, []);
};

export default useResendMemberEmail;
