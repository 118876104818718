import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import type * as React from 'react';

// https://stripe.com/docs/testing#cards

const stripePromise = await loadStripe(import.meta.env.VITE_APP_STRIPE_KEY as string);

type Props = {
    children : React.ReactElement;
};

const StripeWrapper = ({children} : Props) : React.ReactElement => {
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeWrapper;
