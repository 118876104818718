import type {ReactNode} from 'react';
import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import type {User} from '@/types/User';

type UserContextInterface = {
    user : User | null | undefined;
    setUser : (user : User | null | undefined) => void;
    authenticateUser : (email : string, memberId : string) => Promise<Response>;
    signOut : () => void;
};

type UserContextProviderProps = {children : ReactNode};

const UserContext = createContext<UserContextInterface>({} as UserContextInterface);

const useUserContext = () : UserContextInterface => {
    const context = useContext(UserContext);

    if (Object.keys(context).length === 0) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }

    return context;
};

const UserContextProvider = ({children} : UserContextProviderProps) : React.ReactElement => {
    const [user, setUser] = useState<User | null>();

    const authenticateUser = useCallback(async (
        email : string,
        memberId : string,
    ) : Promise<Response> => {
        const init : RequestInit = {
            headers: new Headers({'Accept': 'application/json', 'Content-Type': 'application/json'}),
            method: 'POST',
        };

        init.body = JSON.stringify({
            email: email,
            memberId: memberId,
        });

        const url = new URL('/booster-member/authenticate', import.meta.env.VITE_APP_API_ENDPOINT);

        return await fetch(url.toString(), init);
    }, []);

    const signOut = () : void => {
        localStorage.removeItem('JWTUser');
    };

    useEffect(() => {
        const jwtUser = localStorage.getItem('JWTUser');

        if (jwtUser !== null) {
            setUser(JSON.parse(jwtUser) as User);
        } else {
            setUser(null);
        }
    }, []);

    useEffect(() => {
        if (user !== null && user !== undefined) {
            localStorage.setItem('JWTUser', JSON.stringify(user));
        }
    }, [user]);

    const value = {
        user,
        authenticateUser,
        signOut,
        setUser,
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export {UserContextProvider, useUserContext};
