import {Alert, Box, CardMedia} from '@mui/material';
import type * as React from 'react';
import {useEffect, useState} from 'react';
import useFetchBarcode from '@/hooks/useFetchBarcode';

type BarcodeResponse = {
    memberId : string;
    barcode : string;
};

type Props = {
    memberId : string | null;
};

const Barcode = ({memberId} : Props) : React.ReactElement => {
    const fetchBarcode = useFetchBarcode();
    const [barcodeString, setBarcodeString] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const doFetchBarcode = async (memberId : string) => {
            setBarcodeString('');
            setErrorMessage('');

            const response = await fetchBarcode(memberId);

            if (response.ok) {
                const json = await response.json() as BarcodeResponse;

                if (json.barcode !== '') {
                    setBarcodeString(json.barcode);
                    return;
                }
            }

            setErrorMessage('Error: Unable to fetch Barcode');
        };

        if (memberId) {
            void doFetchBarcode(memberId);
        }
    }, []);

    return (
        <Box sx={{mt: 3, textAlign: 'center'}}>
            {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
            {barcodeString && <CardMedia component='img' src={barcodeString}/>}
        </Box>
    );
};

export default Barcode;
