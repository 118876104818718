import {useCallback} from 'react';
import type {User} from '@/types/User';

const useStripeCreateSetupIntent = () : (
user : User,
membershipTypeId : string,
) => Promise<Response> => {
    return useCallback(async (
        user : User,
        membershipTypeId : string,
    ) : Promise<Response> => {
        const url = new URL('/payment/create-setup-intent', import.meta.env.VITE_APP_API_ENDPOINT);

        return await fetch(url.toString(), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: user.email,
                fullName: user.fullName,
                membershipTypeId: membershipTypeId,
                memberId: user.memberId,
            }),
        });
    }, []);
};

export default useStripeCreateSetupIntent;
