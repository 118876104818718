import LogoutIcon from '@mui/icons-material/Logout';
import {AppBar, Box, Button, Container, Toolbar, Typography} from '@mui/material';
import type * as React from 'react';
import Logo from '@/components/Branding/Logo';
import {useUserContext} from '@/providers/UserContext';

type Props = {
    layoutTitle ?: string;
};

const Header = ({layoutTitle = 'Booster Membership Portal'} : Props) : React.ReactElement => {
    const {user, signOut} = useUserContext();

    return <AppBar
        position="static"
        sx={{
            backgroundColor: '#fff',
            height: '110px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}
    >
        <Toolbar sx={{width: '100%'}}>
            <Container
                maxWidth='lg'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}
            >
                <Box sx={{width: '20%'}}>
                    <Logo/>
                </Box>
                <Typography variant="h6" sx={{color: 'black', pb: 1}}>
                    {layoutTitle}
                </Typography>
                <Box sx={{width: '20%', display: 'flex', justifyContent: 'flex-end'}}>
                    {user !== null && <Button
                        onClick={() => {
                            signOut();
                            window.location.reload();
                            return;
                        }}
                        startIcon={<LogoutIcon sx={{ml: 2}}/>}
                        component="label"
                        sx={{
                            flexDirection: 'column',
                            gap: 1,
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: '#eee',
                                color: '#3c52b2',
                            },
                            color: '#000',
                        }}
                    >
                        Sign-Out
                    </Button>}
                </Box>
            </Container>
        </Toolbar>
    </AppBar>;
};

export default Header;
