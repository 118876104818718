import {Alert, Button, Container, Stack} from '@mui/material';
import {useStripe} from '@stripe/react-stripe-js';
import type * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Header from '@/components/Header';
import useStripeCompleteSetupIntent from '@/hooks/useStripeCompleteSetupIntent';
import {useUserContext} from '@/providers/UserContext';
import type {CreateSetupIntentResponse} from '@/types/CreateSetupIntentResponse';

const CompletePayment = () : React.ReactElement => {
    const stripe = useStripe();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {user} = useUserContext();
    const completeSetupIntent = useStripeCompleteSetupIntent();
    const [paymentProcessed, setPaymentProcessed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [secondsUntilRedirect, setSecondsUntilRedirect] = useState<number | null>(null);

    useEffect(() => {
        const doCompletePayment = async () => {
            const membershipTypeId = searchParams.get('membershipTypeId');
            const boosterMembershipTypeId = searchParams.get('boosterMembershipTypeId');

            if (
                user !== null
                && user !== undefined
                && membershipTypeId !== null
                && boosterMembershipTypeId !== null
                && stripe
            ) {
                setPaymentProcessed(true);

                const setupIntentId = searchParams.get('setup_intent');
                const setupIntentClientSecret = searchParams.get('setup_intent_client_secret');

                if (setupIntentId && setupIntentClientSecret) {
                    const retrieveSetupIntentResponse = await stripe.retrieveSetupIntent(setupIntentClientSecret);

                    if (retrieveSetupIntentResponse.error) {
                        setErrorMessage(
                            retrieveSetupIntentResponse.error.message ?? 'An unknown stripe error has occurred.'
                        );
                        return;
                    }

                    const response = await completeSetupIntent(
                        user,
                        setupIntentId,
                        setupIntentClientSecret,
                        retrieveSetupIntentResponse.setupIntent.payment_method as string,
                        membershipTypeId,
                        boosterMembershipTypeId,
                    );

                    if (response.ok) {
                        const json = await response.json() as CreateSetupIntentResponse;

                        if (json.errorCode !== 0) {
                            setErrorMessage(json.message);
                            return;
                        }

                        setSuccessMessage('Payment Complete');
                        setSecondsUntilRedirect(10);
                    } else {
                        setErrorMessage('An unknown error has occurred.');
                    }
                }
            }
        };

        if (!paymentProcessed) {
            void doCompletePayment();
        }
    }, [user, stripe]);

    useEffect(() => {
        if (secondsUntilRedirect !== null) {
            const intervalId = window.setInterval(() => {
                if (secondsUntilRedirect > 0) {
                    setSecondsUntilRedirect(secondsUntilRedirect - 1);
                    return;
                }

                navigate('/');
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [secondsUntilRedirect]);

    return <Stack direction='column' alignItems='center'>
        <Header layoutTitle='Completing Payment...'/>
        <Container>
            {errorMessage && <Stack direction='column' alignItems='center'>
                <Stack
                    direction='row'
                    justifyContent='center'
                    sx={{mb: 2}}
                >
                    There has been an error processing your request.
                </Stack>
                <Alert severity='error'>{errorMessage}</Alert>
                <Button
                    variant='outlined'
                    onClick={() => {
                        navigate('/');
                    }}
                    sx={{mt: 2}}
                >
                Click here to return to Membership Management
                </Button>
            </Stack>}
            {successMessage && <Stack direction='column' alignItems='center'>
                <Stack
                    direction='row'
                    justifyContent='center'
                    sx={{mb: 2}}
                >
                    You will be redirected to Membership Management in {secondsUntilRedirect}...
                </Stack>
                <Alert severity='success'>{successMessage}</Alert>
                <Button
                    variant='outlined'
                    onClick={() => {
                        navigate('/');
                    }}
                    sx={{mt: 2}}
                >
                Return to Membership Management Now
                </Button>
            </Stack>}
        </Container>
    </Stack>;
};

export default CompletePayment;
