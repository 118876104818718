import {Alert, Box, Container, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import type * as React from 'react';
import {useEffect, useState} from 'react';
import ActiveMemberships from '@/components/ActiveMembershps';
import Barcode from '@/components/Barcode';
import Header from '@/components/Header';
import NewMembershipForm from '@/components/NewMembershipForm';
import PaperWithLabel from '@/components/PaperWithLabel';
import SendMemberIdEmailRequest from '@/components/SendMemberIdEmailRequest';
import useFetchActiveMemberships from '@/hooks/useFetchActiveMemberships';
import {useUserContext} from '@/providers/UserContext';
import type {ActiveMembership} from '@/types/ActiveMembership';
import type {ActiveMembershipsResponse} from '@/types/ActiveMembershipResponse';

const Home = () : React.ReactElement => {
    const {user} = useUserContext();
    const [activeMemberships, setActiveMemberships] = useState<ActiveMembership[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const fetchActiveMemberships = useFetchActiveMemberships();

    useEffect(() => {
        const doFetchActiveMemberships = async (memberId : string) => {
            setErrorMessage('');
            setInfoMessage('');

            const response = await fetchActiveMemberships(memberId);

            if (response.ok) {
                const json = await response.json() as ActiveMembershipsResponse;

                if (json.errorCode !== 0) {
                    setErrorMessage(json.message);
                    return;
                }

                if (json.data.length === 0 && json.message !== '') {
                    setInfoMessage(json.message);
                    return;
                }

                setActiveMemberships(json.data);
                return;
            }

            setErrorMessage('Error: Unable to fetch Active Memberships');
        };

        if (user !== null && user !== undefined) {
            void doFetchActiveMemberships(user.memberId);
        }
    }, []);

    return <Stack direction='column'>
        <Header layoutTitle='Membership Management'/>
        {!user && <Alert severity='error'>User not found.</Alert>}
        {user && <Container>
            <Stack direction='column'>
                <Typography variant='h5' sx={{ml: 4, mt: 2, color: '#fff'}}>Welcome {user.fullName}</Typography>
                <Box sx={{maxWidth: '300px'}}><Barcode memberId={user.memberId}/></Box>
                <Box sx={{backgroundColor: '#fff', mt: 2, mb: 2}}>
                    <SendMemberIdEmailRequest
                        initialEmailValue={user.email}
                        linkText='Re-send Membership ID'
                        linkTextSx={{ml: 4, my: 2}}
                    />
                </Box>
                <PaperWithLabel label='Active Memberships'>
                    <ActiveMemberships
                        activeMemberships={activeMemberships}
                        errorMessage={errorMessage}
                        infoMessage={infoMessage}
                    />
                </PaperWithLabel>
                <PaperWithLabel label='New Membership' sx={{mt: '50px'}}>
                    <NewMembershipForm activeMemberships={activeMemberships}/>
                </PaperWithLabel>
            </Stack>
        </Container>}
    </Stack>;
};

export default Home;
