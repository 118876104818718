import {CircularProgress, Container, Stack} from '@mui/material';
import Header from '@/components/Header';
import SignInForm from '@/components/SignInForm';
import Home from '@/pages/Home';
import {useUserContext} from '@/providers/UserContext';

const IndexWrapper = () : React.ReactElement => {
    const {user} = useUserContext();

    if (user === undefined) {
        return <Container sx={{height: '100%'}}>
            <Stack direction='column' justifyContent='center' alignItems='center' sx={{height: '100%'}}>
                <CircularProgress/>
            </Stack>
        </Container>;
    }

    if (user === null) {
        return <Stack direction='column'>
            <Header layoutTitle='Booster Membership Portal'/>
            <Container>
                <SignInForm/>
            </Container>
        </Stack>;
    }

    return <Home/>;
};

export default IndexWrapper;
