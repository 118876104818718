import {DialogContent, Grid} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import type * as React from 'react';
import StripeForm from '@/components/StripeForm';

export type Props = {
    open : boolean;
    handleClosePaymentModal : () => void;
    amount : number;
    description : string;
    membershipTypeId : string;
    boosterMembershipTypeId : string;
};

const PaymentModal = ({
    open,
    handleClosePaymentModal,
    amount,
    description,
    membershipTypeId,
    boosterMembershipTypeId,
} : Props) : React.ReactElement => {
    return (
        <Dialog onClose={handleClosePaymentModal} open={open}>
            <DialogTitle>Payment Modal</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        All fees must be paid in full by the due date for this activity. Any registrations with
                        outstanding fees after the due date will be cancelled.
                    </Grid>
                    {description !== '' && <Grid item xs={12} sx={{mt: 2, whiteSpace: 'pre-wrap'}}>
                        Description: {description.replaceAll('\r', '\n')}
                    </Grid>}
                    <Grid item xs={12} sx={{mt: 2, fontWeight: 600}}>
                        Cost: ${amount}
                    </Grid>
                    <Grid item xs={12} sx={{my: 2}}>
                        <StripeForm
                            handleClose={handleClosePaymentModal}
                            membershipTypeId={membershipTypeId}
                            boosterMembershipTypeId={boosterMembershipTypeId}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default PaymentModal;
