import {BrowserRouter, Route, Routes} from 'react-router-dom';
import CompletePayment from '@/pages/CompletePayment';
import IndexWrapper from '@/pages/IndexWrapper';
import Barcode from '@/pages/MemberBarcode';
import Register from '@/pages/Register';
import StripeWrapper from '@/pages/StripeWrapper';
import {UserContextProvider} from '@/providers/UserContext';

const App = () : React.ReactElement => {
    return (
        <UserContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<IndexWrapper/>}/>
                    </Route>

                    <Route path="/payment-complete">
                        <Route index element={<StripeWrapper><CompletePayment/></StripeWrapper>}/>
                    </Route>

                    <Route path="/register">
                        <Route index element={<Register/>}/>
                    </Route>

                    <Route path="/barcode">
                        <Route index element={<Barcode/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserContextProvider>
    );
};

export default App;
