import {Container, Stack} from '@mui/material';
import Header from '@/components/Header';
import RegisterForm from '@/components/RegisterForm';

const Register : React.FC = () => {
    return <Stack direction='column'>
        <Header layoutTitle='Membership Registration'/>
        <Container>
            <RegisterForm/>
        </Container>
    </Stack>;
};

export default Register;
