import {zodResolver} from '@hookform/resolvers/zod';
import {Alert, Box, Button, Container, Link, Stack} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';
import PaperWithLabel from '@/components/PaperWithLabel';
import SendMemberIdEmailRequest from '@/components/SendMemberIdEmailRequest';
import {useUserContext} from '@/providers/UserContext';
import type {SignInResponse} from '@/types/SignInResponse';
import {errorMap} from '@/utils/zod';

const studentLoginFormSchema = z.object({
    email: z.string(),
    memberId: z.string(),
});

export type StudentLoginFormSchemaType = z.infer<typeof studentLoginFormSchema>;

const SignInForm = () : React.ReactElement => {
    const {authenticateUser, setUser} = useUserContext();
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState('');

    const form = useForm<StudentLoginFormSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {},
        resolver: zodResolver(studentLoginFormSchema, {errorMap}),
    });

    const emailValue = form.watch('email');

    const handleSubmit = async () => {
        const formValues = form.getValues();
        setLoginError('');

        const response : Response = await authenticateUser(formValues.email, formValues.memberId);

        if (response.ok) {
            const signInResponse = await response.json() as SignInResponse;

            if (signInResponse.errorCode !== 0) {
                setLoginError(signInResponse.message);
                return;
            }

            setUser(signInResponse.data);
            return;
        }

        setLoginError('Error: Login Unsuccessful');
    };

    return <Container maxWidth='sm' sx={{mt: 3}}>
        <PaperWithLabel label='Sign In'>
            <Box>
                <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                    <Stack>
                        <RhfTextField
                            control={form.control}
                            name="email"
                            label="Email Address"
                            placeholder="Email Address"
                            sx={{my: 1}}
                            onChangeCapture={() => {
                                form.clearErrors();
                            }}
                        />
                        <RhfTextField
                            control={form.control}
                            name="memberId"
                            label="Member ID"
                            placeholder="Member ID"
                            sx={{my: 1}}
                            onChangeCapture={() => {
                                form.clearErrors();
                            }}
                        />
                        <Stack direction="row" justifyContent="space-between" sx={{my: 2}}>
                            <SendMemberIdEmailRequest
                                initialEmailValue={emailValue}
                                useModal={true}
                                sx={{
                                    flexDirection: 'column',
                                }}
                            />

                            <Link
                                onClick={() => {
                                    navigate('/register');
                                }}
                                sx={{whiteSpace: 'nowrap'}}
                            >
                                New Membership
                            </Link>
                        </Stack>
                        {loginError && <Stack direction="row" justifyContent="space-between">
                            <Alert severity="error" sx={{width: '100%', my: 2}}>{loginError}</Alert>
                        </Stack>}
                        <Stack direction="row" justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                type="submit"
                                color="primary"
                            >
                                Log In
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </PaperWithLabel>
    </Container>;
};

export default SignInForm;
