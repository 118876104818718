import {useCallback} from 'react';

const useFetchBarcode = () => {
    return useCallback(async (
        memberId : string,
    ) : Promise<Response> => {
        const url = new URL('/booster-member/barcode', import.meta.env.VITE_APP_API_ENDPOINT);
        return await fetch(url.toString(), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                memberId: memberId,
            }),
        });
    }, []);
};

export default useFetchBarcode;
