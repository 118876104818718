import {useCallback} from 'react';

const useRegisterMember = () => {
    return useCallback(async (
        email : string,
        firstName : string,
        lastName : string,
        phone : string,
        address : string,
        city : string,
        state : string,
        zip : string,
    ) : Promise<Response> => {
        const url = new URL('/booster-member/register', import.meta.env.VITE_APP_API_ENDPOINT);

        return await fetch(url.toString(), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                address: address,
                city: city,
                state: state,
                zip: zip,
            }),
        });
    }, []);
};

export default useRegisterMember;
